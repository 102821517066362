import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ArrowBigRightDash, Clock, Cpu, User, BarChart2 } from "lucide-react";
import { BASE_URL } from "../constants";
import { ErrorState, LoadingState } from "./StateComponents";
import { Container } from "./Container";
import { BackgroundImage } from "./BackgroundImage";

const AdminPanel = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCreditUsage();
  }, []);

  const fetchCreditUsage = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/CreditConsumption/credits-by-model`
      );
      setData(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch credit consumption");
      setLoading(false);
    }
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-8">
      <BackgroundImage position="right" className="-bottom-32 -top-40" />
      <Container className="relative">
        <div className="container mx-auto max-w-6xl px-4">
          <h1 className="text-5xl font-extrabold mb-12 text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600 text-left">
            Admin Dashboard
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
            {data.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl"
              >
                <div className="p-6 space-y-4">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold text-indigo-600">
                      {item.model}
                    </h2>
                    <BarChart2 className="text-indigo-400" size={28} />
                  </div>
                  <div className="space-y-3">
                    <div className="flex items-center space-x-3">
                      <Cpu className="text-purple-500" size={24} />
                      <p className="text-gray-700">
                        <span className="font-semibold">Credits:</span>{" "}
                        {item.totalCreditsUsed}
                      </p>
                    </div>
                    <div className="flex items-center space-x-3">
                      <Clock className="text-pink-500" size={24} />
                      <p className="text-gray-700">
                        <span className="font-semibold">Time:</span>{" "}
                        {item.totalProcessingTimeInMinutes} min
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 h-2" />
              </div>
            ))}
          </div>
          <div
            onClick={() => navigate("/user-management")}
            className="bg-white mb-3 rounded-2xl shadow-xl overflow-hidden transition-all duration-300 cursor-pointer group"
          >
            <div className="p-6 flex items-center justify-between bg-gradient-to-r from-indigo-600 to-purple-600 group-hover:from-purple-600 group-hover:to-indigo-600 text-white">
              <div className="flex items-center space-x-4">
                <User size={32} />
                <span className="text-2xl font-bold">User Management</span>
              </div>
              <ArrowBigRightDash
                size={32}
                className="transform transition-transform duration-300 group-hover:translate-x-2"
              />
            </div>
          </div>
          <a
            href="https://echo-ai.sanity.studio/structure/article"
            target="_blank"
            rel="noreferrer"
            className="block bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 cursor-pointer group"
          >
            <div className="p-6 flex items-center justify-between bg-gradient-to-r from-indigo-600 to-purple-600 group-hover:from-purple-600 group-hover:to-indigo-600 text-white">
              <div className="flex items-center space-x-4">
                <User size={32} />
                <span className="text-2xl font-bold">Article Dashboard</span>
              </div>
              <ArrowBigRightDash
                size={32}
                className="transform transition-transform duration-300 group-hover:translate-x-2"
              />
            </div>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default AdminPanel;
