import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  User,
  CreditCard,
  Clock,
  FileAudio,
  CheckCircle,
  XCircle,
  DollarSign,
  Calendar,
  RefreshCw,
  Zap,
} from "lucide-react";
import { BASE_URL, convertSecondsToMinutes } from "../constants";
import AppContext from "../AppContext";
import { BackgroundImage } from "./BackgroundImage";
import { Container } from "./Container";
import { ErrorState, LoadingState } from "./StateComponents";

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [usageHistory, setUsageHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("usage");
  const authCtx = useContext(AppContext);
  const userId = authCtx.profile.id;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, usageResponse] = await Promise.all([
          axios.get(`${BASE_URL}/User/GetById?userId=${userId}`),
          axios.get(`${BASE_URL}/CreditConsumption/user/${userId}`),
        ]);
        setUserDetails(userResponse.data);
        setUsageHistory(usageResponse.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user data");
        setLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  const dummyPayments = [
    { id: 1, amount: 50, date: "2024-08-01", status: "Completed" },
    { id: 2, amount: 100, date: "2024-09-01", status: "Completed" },
    { id: 3, amount: 75, date: "2024-10-01", status: "Pending" },
  ];

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  return (
    <div className="min-h-screen bg-gradient-to-br pb-12">
      <BackgroundImage position="right" className="-bottom-32 -top-40" />
      <Container className="relative">
        <div className="container mx-auto max-w-6xl px-4">
          <h1 className="text-5xl font-bold mb-12 text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600 text-left">
            User Profile
          </h1>

          {/* User Details */}
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-8 transform transition-all duration-300">
            <div className="p-6 space-y-4">
              <div className="flex items-center space-x-4">
                <User size={48} className="text-indigo-600" />
                <div>
                  <h2 className="text-2xl font-bold text-gray-800">
                    {userDetails.name}
                  </h2>
                  <p className="text-gray-600">{userDetails.email}</p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div className="bg-indigo-100 p-4 rounded-lg transform transition-all duration-300 hover:bg-indigo-200">
                  <div className="flex items-center space-x-2 text-indigo-600">
                    <Zap size={24} />
                    <p className="font-semibold">Plan Tier</p>
                  </div>
                  <p className="text-2xl font-bold">{userDetails.planTier}</p>
                </div>
                <div className="bg-purple-100 p-4 rounded-lg transform transition-all duration-300 hover:bg-purple-200">
                  <div className="flex items-center space-x-2 text-purple-600">
                    <CreditCard size={24} />
                    <p className="font-semibold">Remaining Credits</p>
                  </div>
                  <p className="text-2xl font-bold">
                    {convertSecondsToMinutes(userDetails.remainingCredits)}
                  </p>
                </div>
                <div className="bg-pink-100 p-4 rounded-lg transform transition-all duration-300 hover:bg-pink-200">
                  <div className="flex items-center space-x-2 text-pink-600">
                    <RefreshCw size={24} />
                    <p className="font-semibold">Last Credit Reset</p>
                  </div>
                  <p className="text-2xl font-bold">
                    {new Date(userDetails.lastCreditReset).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div className="flex mb-4">
            <button
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === "usage"
                  ? "bg-white text-indigo-600 font-bold"
                  : "bg-gray-200 text-gray-600"
              } rounded-tl-lg rounded-tr-lg`}
              onClick={() => setActiveTab("usage")}
            >
              Usage History
            </button>
            <button
              className={`flex-1 py-2 px-4 text-center ${
                activeTab === "payments"
                  ? "bg-white text-indigo-600 font-bold"
                  : "bg-gray-200 text-gray-600"
              } rounded-tl-lg rounded-tr-lg`}
              onClick={() => setActiveTab("payments")}
            >
              Payment History
            </button>
          </div>

          {/* Payment History */}
          {activeTab === "payments" && (
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-8 transform transition-all duration-300">
              <div className="p-6">
                <h3 className="text-2xl font-bold mb-4 text-gray-800">
                  Payment History
                </h3>
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="bg-gray-100">
                        <th className="p-2 text-left">Date</th>
                        <th className="p-2 text-left">Amount</th>
                        <th className="p-2 text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dummyPayments.map((payment) => (
                        <tr
                          key={payment.id}
                          className="border-b hover:bg-gray-50 transition-colors duration-200"
                        >
                          <td className="p-2 flex items-center">
                            <Calendar
                              size={16}
                              className="mr-2 text-gray-500"
                            />
                            {payment.date}
                          </td>
                          <td className="p-2 ">${payment.amount}</td>
                          <td className="p-2">
                            <span
                              className={`px-2 py-1 rounded flex items-center w-min ${
                                payment.status === "Completed"
                                  ? "bg-green-200 text-green-800"
                                  : "bg-yellow-200 text-yellow-800"
                              }`}
                            >
                              {payment.status === "Completed" ? (
                                <CheckCircle size={16} className="mr-1" />
                              ) : (
                                <Clock size={16} className="mr-1" />
                              )}
                              {payment.status}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {/* Usage History */}
          {activeTab === "usage" && (
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all duration-300">
              <div className="p-6">
                <h3 className="text-2xl font-bold mb-4 text-gray-800">
                  Usage History
                </h3>
                <div className="space-y-4">
                  {usageHistory.map((usage) => (
                    <div
                      key={usage.id}
                      className="border-b pb-4 hover:bg-gray-50 transition-colors duration-200"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <div className="flex items-center space-x-2">
                          <FileAudio className="text-indigo-500" size={24} />
                          <span className="font-semibold">
                            {usage.model} - {usage.operationType}
                          </span>
                        </div>
                        <span
                          className={`px-2 py-1 rounded flex items-center ${
                            usage.status === "Success"
                              ? "bg-green-200 text-green-800"
                              : "bg-red-200 text-red-800"
                          }`}
                        >
                          {usage.status === "Success" ? (
                            <CheckCircle size={16} className="mr-1" />
                          ) : (
                            <XCircle size={16} className="mr-1" />
                          )}
                          {usage.status}
                        </span>
                      </div>
                      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 text-sm">
                        <div>
                          <p className="text-gray-600 flex items-center">
                            <CreditCard size={16} className="mr-1" /> Credits
                            Used
                          </p>
                          <p className="font-semibold">{usage.creditUsed}</p>
                        </div>
                        <div>
                          <p className="text-gray-600 flex items-center">
                            <FileAudio size={16} className="mr-1" /> File Size
                          </p>
                          <p className="font-semibold">{usage.fileSize} MB</p>
                        </div>
                        <div>
                          <p className="text-gray-600 flex items-center">
                            <Clock size={16} className="mr-1" /> Processing Time
                          </p>
                          <p className="font-semibold">
                            {usage.processingTime} s
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-600 flex items-center">
                            <Calendar size={16} className="mr-1" /> Date
                          </p>
                          <p className="font-semibold">
                            {new Date(usage.timestamp).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default UserProfile;
