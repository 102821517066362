import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { AssemblyAI } from "assemblyai";
import {
  Play,
  Pause,
  Upload,
  Copy,
  CheckCircle,
  Share2,
  Download,
} from "lucide-react";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";
import AppContext from "../AppContext";
import useClipboard from "./useClipboard";
import LoadingButton from "./LoadingButton";
import { BlobServiceClient } from "@azure/storage-blob";
import { BASE_FUN_URL, BASE_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import useStatusIndicator from "./StatusIndicator";
import StepIndicator from "./StepBar";

export default function VideoToText() {
  const [file, setFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [data, setData] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);
  const mediaRef = useRef(null);

  const progressRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [fileName, setFileName] = useState(false);
  // const { progress, nextStep, reset } = useOverallProgress(4);
  const [status, setStatus] = useState("");

  const authCtx = useContext(AppContext);
  const {
    isCopied: isTranscriptionCopied,
    copyToClipboard: copyTranscription,
  } = useClipboard();
  const { isCopied: isSummaryCopied, copyToClipboard: copySummary } =
    useClipboard();
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("video/")) {
      setFile(file);
      setMediaUrl(URL.createObjectURL(file));
    } else {
      toast.error("Please upload an video file only.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*",
  });
  const exportToPDF = (text) => {
    const doc = new jsPDF();
    const lines = doc.splitTextToSize(text, 180); // 180 is the maximum width of the PDF page
    let y = 10; // starting y-coordinate for the text
    const lineHeight = 10; // adjust this value to change the spacing between lines

    for (let i = 0; i < lines.length; i++) {
      doc.text(lines[i], 10, y);
      y += lineHeight;
    }

    doc.save("transcription.pdf");
  };

  const shareTranscription = (text) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Transcription",
          text,
        })
        .catch((error) => console.error("Error sharing", error));
    } else {
      alert("Sharing not supported on this device");
    }
  };
  const triggerTranscription = (id) => {
    axios.get(
      `${BASE_FUN_URL}/api/transcribe/${authCtx.profile.email}/${
        fileName ? fileName : id
      }`
    );
  };
  const fetchLatestFileLog = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Lookup/file-log/latest/${authCtx.profile.email}?fileType=Video`
      );

      if (response.data) {
        const { id, fileUrl, transcription, status } = response.data;
        setIsLoading(true);
        if (status === "Uploading") {
          return;
        } else if (status === "Processing") {
          setFileName(id);
          setCurrentStep(2);
          setMediaUrl(fileUrl);
          triggerTranscription(id);
          pollingTranscription(id);
        } else {
          setFileName(id);
          setCurrentStep(3);
          setTranscription(transcription);
          setIsLoading(false);
          setMediaUrl(fileUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching the latest file log:", error);
    }
  };
  useEffect(() => {
    const media = mediaRef.current;
    if (media) {
      const setMediaData = () => {
        setDuration(media.duration);
        setCurrentTime(media.currentTime);
      };

      const setMediaTime = () => setCurrentTime(media.currentTime);

      media.addEventListener("loadeddata", setMediaData);
      media.addEventListener("timeupdate", setMediaTime);

      return () => {
        media.removeEventListener("loadeddata", setMediaData);
        media.removeEventListener("timeupdate", setMediaTime);
      };
    }
  }, [mediaUrl]);
  const handleUpload = async () => {
    try {
      if (!file) {
        toast.error("Please select a file to upload.");
        return;
      }
      setIsLoading(true);
      const fileExtension = file.name.split(".").pop();

      const sasResponse = await axios.post(`${BASE_URL}/Lookup/get_sas_token`, {
        extension: fileExtension,
        duration: parseInt(duration),
        userEmail: authCtx.profile.email, // Use the sanitized email
      });

      const { sasToken, blobUrl, blobName, documentId } = sasResponse.data;

      const baseUrl = blobUrl.split(`/${blobName}`)[0];

      const fullBlobUrl = `${baseUrl}?${sasToken}`;

      const blobServiceClient = new BlobServiceClient(fullBlobUrl);
      const containerClient = blobServiceClient.getContainerClient("");
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        onProgress: (ev) => {
          console.log(`Uploaded ${ev.loadedBytes} bytes of ${file.size}`);
          const uploadedMB = (ev.loadedBytes / (1024 * 1024)).toFixed(2);
          const totalMB = (file.size / (1024 * 1024)).toFixed(2);
          // Update progress state
          setUploadProgress({
            uploadedMB,
            totalMB,
          });
        },
      });
      setCurrentStep(2);
      setFileName(`${documentId}.${fileExtension}`);
    } catch (error) {
      console.error("Error uploading media:", error);
      toast.error("Failed to upload the file. Please try again.");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  const pollingTranscription = (id) => {
    try {
      setStatus("awaiting Processing");
      const checkStatusInterval = setInterval(async () => {
        try {
          const statusResponse = await axios.get(
            `${BASE_URL}/Lookup/file-log/${fileName ? fileName : id}`
          );
          const updatedDocument = statusResponse.data;

          if (updatedDocument.status !== data.status) {
            setData(updatedDocument);
          }
          console.log(updatedDocument);

          if (updatedDocument.status === "Completed") {
            clearInterval(checkStatusInterval);

            if (updatedDocument.transcription) {
              setTranscription(updatedDocument.transcription);
              setCurrentStep(3);
              setIsLoading(false);
            }
            setStatus("Completed");
          }
        } catch (error) {
          console.error("Error fetching document status:", error);
          clearInterval(checkStatusInterval);
          setStatus("Completed");
        }
      }, 5000);
    } catch (error) {
      console.error("Error transcribing media:", error);
      setIsLoading(false);
      setStatus("");
      toast.error("Failed to transcribe the audio. Please try again.");
    }
  };
  const handleTranscribe = async (file) => {
    if (!authCtx.profile) {
      toast.error("Please log in to use this feature.");
      return;
    }

    setIsLoading(true);
    triggerTranscription();
    pollingTranscription();
  };
  useEffect(() => {
    fetchLatestFileLog();
  }, []);

  const handleSummarize = async () => {
    if (!transcription) return;
    setIsLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/Lookup/summarize`, {
        text: transcription,
      });
      setSummary(response.data.summary);
    } catch (error) {
      console.error("Error summarizing text:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePlayPause = () => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleProgressChange = (e) => {
    const media = mediaRef.current;
    if (media) {
      const newTime = (e.target.value / 100) * media.duration;
      media.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };
  const startNew = () => {
    setTranscription();
    setCurrentStep(1);
    setFileName(null);
    setSummary(null);
    setFile(null);
    setMediaUrl(null);
  };
  return (
    <div className="flex flex-col items-center h-fit justify-center text-white">
      <StepIndicator currentStep={currentStep} />
      <div className="flex justify-center from-purple-500 to-blue-500 lg:bg-gradient-to-r my-3 sm:my-6 bg-white text-gray-800 lg:text-gray-50 rounded-xl sm:rounded-3xl shadow-xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl">
        <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
          <div className="text-base sm:text-lg font-bold">STEP 1</div>
          <div>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold mb-2 sm:mb-4">
              VideoScribe
            </h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-8">
              Transcribe video to text automatically, using AI. Over +120
              languages supported.
            </p>
          </div>

          <div className="bg-blue-50 rounded-xl sm:rounded-2xl p-4 sm:p-8 shadow-lg">
            {!file && !mediaUrl ? (
              <div
                className="flex flex-col items-center justify-center h-32 sm:h-48"
                {...getRootProps()}
              >
                <input {...getInputProps()} />

                <p className="text-base sm:text-xl text-gray-600 text-center">
                  Drag and drop your video file here
                </p>
              </div>
            ) : mediaUrl ? (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={48} className="text-blue-500 mr-4" />
                    ) : (
                      <Play size={48} className="text-blue-500 mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                      ref={progressRef}
                    />
                  </div>
                </div>
                <div className="flex justify-between text-sm text-gray-600 mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <audio
                  ref={mediaRef}
                  src={mediaUrl}
                  controls
                  className="w-full hidden"
                />
              </>
            ) : (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={32} className="text-blue-500 mr-2 sm:mr-4" />
                    ) : (
                      <Play size={32} className="text-blue-500 mr-2 sm:mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex justify-between text-xs sm:text-sm text-gray-600 mb-2 sm:mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <audio src={mediaUrl} controls className="w-full hidden" />
              </>
            )}
          </div>

          <div className="flex w-full justify-end">
            {!file && !mediaUrl ? (
              <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 sm:overflow-hidden sm:rounded-full">
                <div
                  {...getRootProps()}
                  className="bg-blue-600 text-white px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-blue-700 transition duration-300 cursor-pointer flex items-center justify-center flex-1 rounded-full sm:rounded-none"
                >
                  <input {...getInputProps()} />
                  <Upload className="mr-2" size={20} />
                  Upload
                </div>
              </div>
            ) : !fileName ? (
              <div className="flex flex-col sm:flex-row w-full gap-2">
                <LoadingButton
                  onClick={handleUpload}
                  className="bg-blue-600 text-white px-6 py-3 sm:px-8 sm:py-4 w-full sm:w-3/5 rounded-full text-base sm:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)] transition duration-300"
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading" : "Upload"}
                </LoadingButton>
                <div
                  onClick={startNew}
                  className="bg-gray-600 text-white rounded-full w-full sm:w-2/5 px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                >
                  Go back
                </div>
              </div>
            ) : (
              <div className="text-xs sm:text-sm text-white font-semibold flex items-center">
                {uploadProgress && (
                  <span>
                    {uploadProgress.uploadedMB} MB of {uploadProgress.totalMB}{" "}
                    MB uploaded
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {currentStep >= 2 && (
        <div className="flex justify-center from-purple-500 to-blue-500 lg:bg-gradient-to-r my-3 sm:my-6 bg-white text-gray-800 lg:text-gray-50 rounded-xl sm:rounded-3xl shadow-xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl">
          <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
            <div className="text-base sm:text-lg font-bold">STEP 2</div>
            <div>
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold mb-2 lg:mb-4 lg:text-left">
                Transcription
              </h2>
              {/* <p className="text-lg mb-8">
                  Transcribe audio to text automatically, using AI. Over +120
                  languages supported.
                </p> */}
            </div>

            <div className="bg-blue-50 rounded-2xl p-4 md:p-6 lg:p-8 shadow-lg">
              <>
                <div className="bg-white rounded-2xl p-4 md:p-6 shadow-sm">
                  <p className="text-lg md:text-xl lg:text-2xl text-gray-800 max-h-48 lg:max-h-56 overflow-y-auto">
                    {transcription || "Transcription will appear here..."}
                  </p>
                </div>
                {transcription && (
                  <div className="flex justify-center lg:justify-start gap-2">
                    <button
                      onClick={() => copyTranscription(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Copy transcription"
                    >
                      {isTranscriptionCopied ? (
                        <CheckCircle size={24} className="text-green-500" />
                      ) : (
                        <Copy size={24} className="text-gray-500" />
                      )}
                    </button>

                    <button
                      onClick={() => shareTranscription(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Share transcription"
                    >
                      <Share2 size={24} className="text-gray-500" />
                    </button>

                    <button
                      onClick={() => exportToPDF(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Export to PDF"
                    >
                      <Download size={24} className="text-gray-500" />
                    </button>
                  </div>
                )}
              </>
            </div>
            <div></div>
            {!transcription ? (
              <div className="flex w-full justify-end">
                <div className="gap-2 flex w-full flex-col lg:flex-row">
                  <LoadingButton
                    onClick={handleTranscribe}
                    className="bg-blue-600 text-white px-6 py-3 lg:px-8 lg:py-4 w-full lg:w-3/5 rounded-full text-lg lg:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                    disabled={isLoading}
                  >
                    {isLoading ? "Converting" : "Convert to text"}
                  </LoadingButton>
                  <div
                    onClick={startNew}
                    className="bg-gray-600 text-white rounded-full w-full lg:w-2/5 px-6 py-3 lg:px-6 lg:py-4 text-lg lg:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                  >
                    Go back
                  </div>
                </div>
              </div>
            ) : !summary ? (
              <div className="flex w-full justify-end">
                <div className="gap-2 flex w-full flex-col lg:flex-row">
                  <button
                    onClick={handleSummarize}
                    disabled={isLoading}
                    className="bg-blue-600 text-white px-6 py-3 lg:px-8 lg:py-4 w-full lg:w-3/5 rounded-full text-lg lg:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                  >
                    {isLoading ? "Summarizing..." : "Summarize"}
                  </button>
                  <div
                    onClick={startNew}
                    className="bg-gray-600 text-white rounded-full w-full lg:w-2/5 px-6 py-3 lg:px-6 lg:py-4 text-lg lg:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                  >
                    Start new
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {summary && (
        <div className="flex justify-center from-purple-500 to-blue-500 lg:bg-gradient-to-r my-3 sm:my-6 bg-white text-gray-800 lg:text-gray-50 rounded-xl sm:rounded-3xl shadow-xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl">
          <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
            <div className="text-lg font-bold">STEP 3</div>
            <h2 className="text-4xl font-extrabold mb-4">Summary</h2>

            <div className="bg-blue-50 rounded-lg p-6 shadow-sm">
              <p className="text-xl bg-white rounded-2xl p-8 shadow-lg text-gray-800">
                {summary}
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => copySummary(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Copy summary"
                >
                  {isSummaryCopied ? (
                    <CheckCircle size={24} className="text-green-500" />
                  ) : (
                    <Copy size={24} className="text-gray-500" />
                  )}
                </button>

                <button
                  onClick={() => shareTranscription(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Share summary"
                >
                  <Share2 size={24} className="text-gray-500" />
                </button>

                <button
                  onClick={() => exportToPDF(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Export to PDF"
                >
                  <Download size={24} className="text-gray-500" />
                </button>
              </div>
            </div>
            <div className="flex w-full justify-end mt-3">
              <div className="gap-2 flex w-full">
                <button
                  onClick={startNew}
                  className="bg-blue-600 text-white px-8 py-4 w-3/5 rounded-full text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                >
                  Start new
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
