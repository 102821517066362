import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Key,
  Plus,
  Minus,
  User,
  Mail,
  Calendar,
  CreditCard,
} from "lucide-react";
import { BASE_URL, convertSecondsToMinutes } from "../constants";
import { ErrorState, LoadingState, NotFoundState } from "./StateComponents";

const UserManagementPanel = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserDetails();
  }, [id]);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/User/GetById?userId=${id}`);
      setUser(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch user details");
      setLoading(false);
    }
  };

  const handleToggleUser = async () => {
    try {
      await axios.put(`${BASE_URL}/User/ToggleActive/${id}`);
      setUser({ ...user, active: !user.active });
    } catch (err) {
      setError("Failed to toggle user status");
    }
  };

  const handleResetPassword = async () => {
    try {
      await axios.post(`${BASE_URL}/User/ResetPassword/${id}`);
      alert("Password reset email sent to user.");
    } catch (err) {
      setError("Failed to reset password");
    }
  };

  const handleAddCredits = async () => {
    try {
      await axios.post(`${BASE_URL}/User/UpdateRemainingMinutes`, {
        userId: id,
        credit: 600,
        IsIncrease: true,
      });

      setUser({ ...user, remainingCredits: user.remainingCredits + 600 });
    } catch (err) {
      setError("Failed to add credits");
    }
  };

  const handleRemoveCredits = async () => {
    try {
      await axios.post(`${BASE_URL}/User/UpdateRemainingMinutes`, {
        userId: id,
        credit: 600,
        IsIncrease: false,
      });
      setUser({
        ...user,
        remainingCredits: Math.max(0, user.remainingCredits - 600),
      });
    } catch (err) {
      setError("Failed to remove credits");
    }
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!user) return <NotFoundState />;

  return (
    <div className="max-w-6xl mx-auto mb-10 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-semibold text-gray-900 mb-6">
        User Management Panel
      </h1>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            User Information
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <User size={18} className="mr-2" /> Full name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Mail size={18} className="mr-2" /> Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.email}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <CreditCard size={18} className="mr-2" /> Plan tier
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
                {user.planTier}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 flex items-center">
                <Calendar size={18} className="mr-2" /> Last credit reset
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {new Date(user.lastCreditReset).toLocaleString()}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                User Status
              </h3>
              <button
                onClick={handleToggleUser}
                className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                  user.active ? "bg-indigo-600" : "bg-gray-200"
                }`}
              >
                <span
                  className={`${
                    user.active ? "translate-x-5" : "translate-x-0"
                  } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                />
              </button>
            </div>
            <p className="mt-2 text-sm text-gray-500">
              User is currently {user.active ? "Active" : "Inactive"}
            </p>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Reset Password
            </h3>
            <button
              onClick={handleResetPassword}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <Key size={18} className="mr-2" /> Reset Password
            </button>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg sm:col-span-2">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Manage Credits
            </h3>
            <div className="flex items-center justify-between">
              <p className="text-2xl font-semibold text-gray-900">
                {convertSecondsToMinutes(user.remainingCredits)} credits
              </p>
              <div className="flex space-x-3">
                <button
                  onClick={handleAddCredits}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <Plus size={18} className="mr-2" /> Add Credits
                </button>
                <button
                  onClick={handleRemoveCredits}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <Minus size={18} className="mr-2" /> Remove Credits
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagementPanel;
