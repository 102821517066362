import "./App.css";
import AudioUpload from "./Components/AudioUpload";
import { useContext, useEffect } from "react";
import { Layout } from "./Components/Layout";
import { HashRouter, Route, Routes, useNavigation } from "react-router-dom";
import Home from "./Components/Home";
import AudioToText from "./Components/AudioToText";
import VideoToText from "./Components/VideoToText";
import AudioToVisual from "./Components/TestVishualAudio";
import AppContext from "./AppContext";
import NotFound from "./Components/NotFound";
import "react-toastify/dist/ReactToastify.css";
import { gapi } from "gapi-script";
import { Client_id } from "./constants";
import { ToastContainer } from "react-toastify";
import ArticleDetail from "./Components/ArticleDetail";
import ArticleList from "./Components/ArticleList";
import TermsAndConditions from "./Components/TermsAndConditions";
import SignupTermsAndConditions from "./Components/TermsAndConditions";
import UserActivate from "./Components/UserActivate";
import UserManagementPanel from "./Components/UserManagementPanel";
import UserList from "./Components/UserList";
import AdminPanel from "./Components/AdminPanel";
import UserProfile from "./Components/UserProfile";

function App() {
  const authCtx = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;
  useEffect(() => {
    async function start() {
      try {
        await gapi.client.init({
          clientId: Client_id,
          scope: "",
        });
      } catch (error) {
        console.error("Error initializing Google client:", error);
      }
    }

    gapi.load("client:auth2", start);
    authCtx.checkCredit();
  }, []);
  return (
    <>
      <HashRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            {isLoggedIn && (
              <>
                {authCtx.profile.isAdmin && (
                  <>
                    <Route path="/user/:id" element={<UserManagementPanel />} />
                    <Route path="/admin-panel" element={<AdminPanel />} />
                    <Route path="/user-management" element={<UserList />} />
                  </>
                )}

                <Route path="/AudioUpload" element={<AudioUpload />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/audio-to-text" element={<AudioToText />} />
                <Route path="/video-to-text" element={<VideoToText />} />
                <Route path="/audio-to-text/:id" element={<AudioToText />} />
                <Route path="/video-to-text/:id" element={<VideoToText />} />
              </>
            )}
            <Route path="*" element={<NotFound />} />
            <Route path="/audio-to-visual" element={<AudioToVisual />} />
            <Route
              path="/signup-terms-and-conditions"
              element={<SignupTermsAndConditions />}
            />
            <Route path="/activate/:token" element={<UserActivate />} />
            <Route path="/document/:slug" element={<TermsAndConditions />} />
            <Route path="/articles" element={<ArticleList />} />
            <Route path="/article/:slug" element={<ArticleDetail />} />
          </Routes>
        </Layout>
      </HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </>
  );
}

export default App;
