import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useDropzone } from "react-dropzone";
import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import {
  Play,
  Pause,
  Upload,
  Mic,
  StopCircle,
  CheckCircle,
  Copy,
  Share2,
  Download,
} from "lucide-react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import useClipboard from "./useClipboard";
import { toast } from "react-toastify";
import AppContext from "../AppContext";
import { useOverallProgress } from "../hooks/useOverallProgress";
import { ProgressBar } from "./ProgressBar";
import LoadingButton from "./LoadingButton";
import { BASE_FUN_URL, BASE_URL, FLASK_URL } from "../constants";
import { BlobServiceClient } from "@azure/storage-blob";
import useStatusIndicator from "./StatusIndicator";
import StepIndicator from "./StepBar";
import jsPDF from "jspdf";
import { useNavigate, useParams } from "react-router-dom";
const API_BASE_URL = "https://yasmin-ai-141639051694.europe-west1.run.app"; // Update with your actual API base URL

export default function AudioToText() {
  const [file, setFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [gcsUri, setGcsUri] = useState(null);const [fileName, setFileName] = useState(false);
  const [transcriptionProgress, setTranscriptionProgress] = useState(0);
  const authCtx = useContext(AppContext);
  const mediaRef = useRef(null);
  const progressRef = useRef(null);
  const recorderRef = useRef(null);

  const { isCopied: isTranscriptionCopied, copyToClipboard: copyTranscription } = useClipboard();
  const { isCopied: isSummaryCopied, copyToClipboard: copySummary } = useClipboard();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("audio/")) {
      setFile(file);
      setMediaUrl(URL.createObjectURL(file));
    } else {
      toast.error("Please upload an audio file only.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "audio/*",
    multiple: false,
  });

  // Upload file to GCP bucket
  const handleUpload = async () => {
    try {
      if (!file) {
        toast.error("Please select a file to upload.");
        return;
      }
      setIsLoading(true);

      // const formData = new FormData();
      // formData.append('file', file);
      // formData.append('email', authCtx.profile.email);

      // const uploadResponse = await axios.post(`${API_BASE_URL}/upload`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // });

      const gcs_uri = await uploadFileInChunks(file);
      debugger;
      setGcsUri(gcs_uri);
      setCurrentStep(2);
      toast.success("File uploaded successfully!");
      
    } catch (error) {
      console.error("Error in upload process:", error);
      toast.error("Failed to upload the file. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  async function uploadFileInChunks(file) {
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let start = 0;

    // Step 1: Get Upload URL from Flask
    const initResponse = await axios.post(`${API_BASE_URL}/upload/initiate`, {
        email: authCtx.profile.email,
        fileName: file.name
    });

    let uploadUrl = initResponse.data.uploadUrl; // Initial resumable upload URL

    // Step 2: Upload File in Chunks
    for (let chunkNumber = 0; chunkNumber < totalChunks; chunkNumber++) {
        let end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const contentLength = end - start;

        try {
            const response = await fetch(uploadUrl, {
                method: "PUT",
                body: chunk,
                headers: {
                    "Content-Type": "application/octet-stream",
                    "Content-Range": `bytes ${start}-${end - 1}/${file.size}`
                }
            });

            if (response.status === 308) {
                // Get new upload URL if redirected
                const newUploadUrl = response.headers.get("Location");
                if (newUploadUrl) {
                    uploadUrl = newUploadUrl;
                    console.log("🔄 Redirect detected, updating upload URL:", uploadUrl);
                }
            } else if (!response.ok) {
                throw new Error(`Chunk upload failed: ${response.statusText}`);
            }
        } catch (error) {
            console.error("❌ Error uploading chunk:", error);
            return null;
        }

        start = end; // Move to the next chunk
    }

    // Step 3: Complete Upload
    const completeResponse = await axios.post(`${API_BASE_URL}/upload/complete`, {
        email: authCtx.profile.email,
        fileName: file.name
    });

    return completeResponse.data.gcsUri;
}



  // Simulate transcription progress
  const simulateProgress = () => {
    setTranscriptionProgress(0);
    const interval = setInterval(() => {
      setTranscriptionProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 90;
        }
        return prev + 2;
      });
    }, 500);
    return interval;
  };

  // Handle transcription
  const handleTranscribe = async () => {
    if (!gcsUri) {
        toast.error("Please upload a file first.");
        return;
    }

    setIsLoading(true);
    setTranscriptionProgress(0);

    try {
        // Start transcription
        const transcribeResponse = await axios.post(`${API_BASE_URL}/transcribe/start`, { gcs_uri: gcsUri });
        const { task_id } = transcribeResponse.data;

        // Poll for progress
        const pollTranscription = async () => {
            try {
                const progressResponse = await axios.get(`${API_BASE_URL}/transcribe/status/${task_id}`);
                const { status, progress, transcript } = progressResponse.data;

                if (status === "completed") {
                    setTranscription(transcript);
                    setTranscriptionProgress(100);
                    setIsLoading(false);
                    setCurrentStep(3);
                    toast.success("Transcription completed!");
                } else if (status === "failed") {
                    setIsLoading(false);
                    toast.error("Transcription failed.");
                } else {
                    setTranscriptionProgress(progress || 50); // Default to 50% if no progress is given
                    setTimeout(pollTranscription, 3000); // Poll every 3 seconds
                }
            } catch (error) {
                setIsLoading(false);
                toast.error("Failed to fetch transcription progress.");
            }
        };

        pollTranscription();
    } catch (error) {
        setIsLoading(false);
        toast.error("Failed to start transcription.");
    }
};
  

  // Handle summarization
  const handleSummarize = async () => {
    if (!transcription) return;
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/summarize`, {
        text: transcription
      });
      setSummary(response.data.summary);
      toast.success("Summary generated successfully!");
    } catch (error) {
      console.error("Error summarizing text:", error);
      toast.error("Failed to generate summary. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Recording functionality remains the same...
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorderRef.current = recorder;
      
      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        const file = new File([blob], "recorded_audio.webm", { type: "audio/webm" });
        setFile(file);
        setMediaUrl(URL.createObjectURL(blob));
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
      toast.error("Failed to start recording. Please check your microphone permissions.");
    }
  };

  const stopRecording = () => {
    if (recorderRef.current && isRecording) {
      recorderRef.current.stop();
      setIsRecording(false);
      setMediaRecorder(null);
    }
  };

  // Reset all states
  const startNew = () => {
    setTranscription("");
    setCurrentStep(1);
    setSummary("");
    setFile(null);
    setMediaUrl(null);
    setGcsUri(null);
    setTranscriptionProgress(0);
  };

  // Audio player controls remain the same...
  const togglePlayPause = () => {
    if (mediaRef.current) {
      if (isPlaying) {
        mediaRef.current.pause();
      } else {
        mediaRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleProgressChange = (e) => {
    const media = mediaRef.current;
    if (media) {
      const newTime = (e.target.value / 100) * media.duration;
      media.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const exportToPDF = (text) => {
    const doc = new jsPDF();
    const lines = doc.splitTextToSize(text, 180);
    let y = 10;
    const lineHeight = 10;

    lines.forEach(line => {
      doc.text(line, 10, y);
      y += lineHeight;
    });

    doc.save("transcription.pdf");
  };
  const shareTranscription = (text) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Transcription",
          text,
        })
        .catch((error) => console.error("Error sharing", error));
    } else {
      alert("Sharing not supported on this device");
    }
  };
  return (
    <div className="flex flex-col items-center h-fit justify-center text-white">
      <StepIndicator currentStep={currentStep} />
      <div className="flex justify-center from-purple-500 to-blue-500 lg:bg-gradient-to-r my-3 sm:my-6 bg-white text-gray-800 lg:text-gray-50 rounded-xl sm:rounded-3xl shadow-xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl">
        <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
          <div className="text-base sm:text-lg font-bold">STEP 1</div>
          <div>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold mb-2 sm:mb-4">
              VoiceScribe
            </h2>
            <p className="text-base sm:text-lg mb-4 sm:mb-8">
              Transcribe audio to text automatically, using AI. Over +120
              languages supported.
            </p>
          </div>

          <div className="bg-blue-50 rounded-xl sm:rounded-2xl p-4 sm:p-8 shadow-lg">
            {!file && !mediaUrl ? (
              <div
                className="flex flex-col items-center justify-center h-32 sm:h-48"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isRecording && mediaRecorder ? (
                  <div className="mt-4">
                    <LiveAudioVisualizer
                      mediaRecorder={mediaRecorder}
                      width={300}
                      height={50}
                    />
                  </div>
                ) : (
                  <p className="text-base sm:text-xl text-gray-600 text-center">
                    Drag and drop your audio file here
                  </p>
                )}
              </div>
            ) : mediaUrl ? (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={48} className="text-blue-500 mr-4" />
                    ) : (
                      <Play size={48} className="text-blue-500 mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                      ref={progressRef}
                    />
                  </div>
                </div>
                <div className="flex justify-between text-sm text-gray-600 mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <audio
                  ref={mediaRef}
                  src={mediaUrl}
                  controls
                  className="w-full hidden"
                />
              </>
            ) : (
              <>
                <div className="flex items-center mb-2">
                  <button
                    onClick={togglePlayPause}
                    className="focus:outline-none"
                  >
                    {isPlaying ? (
                      <Pause size={32} className="text-blue-500 mr-2 sm:mr-4" />
                    ) : (
                      <Play size={32} className="text-blue-500 mr-2 sm:mr-4" />
                    )}
                  </button>
                  <div className="flex-grow">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={(currentTime / duration) * 100 || 0}
                      onChange={handleProgressChange}
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="flex justify-between text-xs sm:text-sm text-gray-600 mb-2 sm:mb-4">
                  <span>{formatTime(currentTime)}</span>
                  <span>{formatTime(duration)}</span>
                </div>
                <audio src={mediaUrl} controls className="w-full hidden" />
              </>
            )}
          </div>

          <div className="flex w-full justify-end">
            {!file && !mediaUrl ? (
              <div className="flex w-full flex-col sm:flex-row gap-2 sm:gap-0 sm:overflow-hidden sm:rounded-full">
                <div
                  {...getRootProps()}
                  className="bg-blue-600 text-white px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-blue-700 transition duration-300 cursor-pointer flex items-center justify-center flex-1 rounded-full sm:rounded-none"
                >
                  <input {...getInputProps()} />
                  <Upload className="mr-2" size={20} />
                  Upload
                </div>
                <button
                  onClick={isRecording ? stopRecording : startRecording}
                  className="bg-red-500 text-white px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-red-600 transition duration-300 flex items-center justify-center flex-1 rounded-full sm:rounded-none sm:border-l sm:border-red-400"
                >
                  {isRecording ? (
                    <>
                      <StopCircle className="mr-2" size={20} />
                      Stop Recording
                    </>
                  ) : (
                    <>
                      <Mic className="mr-2" size={20} />
                      Record
                    </>
                  )}
                </button>
              </div>
            ) : currentStep == 1 ? (
              <div className="flex flex-col sm:flex-row w-full gap-2">
                <LoadingButton
                  onClick={handleUpload}
                  className="bg-blue-600 text-white px-6 py-3 sm:px-8 sm:py-4 w-full sm:w-3/5 rounded-full text-base sm:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)] transition duration-300"
                  disabled={isLoading}
                >
                  {isLoading ? "Uploading" : "Upload"}
                </LoadingButton>
                <div
                  onClick={startNew}
                  className="bg-gray-600 text-white rounded-full w-full sm:w-2/5 px-4 py-3 sm:px-6 sm:py-4 text-base sm:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                >
                  Go back
                </div>
              </div>
            ) : (
              <div className="text-xs sm:text-sm text-white font-semibold flex items-center">
                {/* {uploadProgress && (
                  <span>
                    {uploadProgress.uploadedMB} MB of {uploadProgress.totalMB}{" "}
                    MB uploaded
                  </span>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>
      {currentStep >= 2 && (
        <div className="flex justify-center from-purple-500 to-blue-500 lg:bg-gradient-to-r my-3 sm:my-6 bg-white text-gray-800 lg:text-gray-50 rounded-xl sm:rounded-3xl shadow-xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl">
          <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
            <div className="text-base sm:text-lg font-bold">STEP 2</div>
            <div>
              <h2 className="text-2xl md:text-3xl lg:text-4xl font-extrabold mb-2 lg:mb-4 lg:text-left">
                Transcription
              </h2>
              {/* <p className="text-lg mb-8">
                  Transcribe audio to text automatically, using AI. Over +120
                  languages supported.
                </p> */}
            </div>
            {isLoading && (
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                  style={{ width: `${transcriptionProgress}%` }}
                ></div>
              </div>
            )}
            <div className="bg-blue-50 rounded-2xl p-4 md:p-6 lg:p-8 shadow-lg">
              <>
                <div className="bg-white rounded-2xl p-4 md:p-6 shadow-sm">
                  <p className="text-lg md:text-xl lg:text-2xl text-gray-800 max-h-48 lg:max-h-56 overflow-y-auto">
                    {transcription || "Transcription will appear here..."}
                  </p>
                </div>
                {transcription && (
                  <div className="flex justify-center lg:justify-start gap-2">
                    <button
                      onClick={() => copyTranscription(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Copy transcription"
                    >
                      {isTranscriptionCopied ? (
                        <CheckCircle size={24} className="text-green-500" />
                      ) : (
                        <Copy size={24} className="text-gray-500" />
                      )}
                    </button>

                    <button
                      onClick={() => shareTranscription(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Share transcription"
                    >
                      <Share2 size={24} className="text-gray-500" />
                    </button>

                    <button
                      onClick={() => exportToPDF(transcription)}
                      className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                      title="Export to PDF"
                    >
                      <Download size={24} className="text-gray-500" />
                    </button>
                  </div>
                )}
              </>
            </div>
            <div></div>
            {!transcription ? (
              <div className="flex w-full justify-end">
                <div className="gap-2 flex w-full flex-col lg:flex-row">
                  <LoadingButton
                    onClick={handleTranscribe}
                    className="bg-blue-600 text-white px-6 py-3 lg:px-8 lg:py-4 w-full lg:w-3/5 rounded-full text-lg lg:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                    disabled={isLoading}
                  >
                    {isLoading ? "Converting" : "Convert to text"}
                  </LoadingButton>
                  <div
                    onClick={startNew}
                    className="bg-gray-600 text-white rounded-full w-full lg:w-2/5 px-6 py-3 lg:px-6 lg:py-4 text-lg lg:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                  >
                    Go back
                  </div>
                </div>
              </div>
            ) : !summary ? (
              <div className="flex w-full justify-end">
                <div className="gap-2 flex w-full flex-col lg:flex-row">
                  <button
                    onClick={handleSummarize}
                    disabled={isLoading}
                    className="bg-blue-600 text-white px-6 py-3 lg:px-8 lg:py-4 w-full lg:w-3/5 rounded-full text-lg lg:text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                  >
                    {isLoading ? "Summarizing..." : "Summarize"}
                  </button>
                  <div
                    onClick={startNew}
                    className="bg-gray-600 text-white rounded-full w-full lg:w-2/5 px-6 py-3 lg:px-6 lg:py-4 text-lg lg:text-xl font-semibold hover:bg-gray-700 transition duration-300 cursor-pointer flex items-center justify-center"
                  >
                    Start new
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {summary && (
        <div className="flex justify-center from-purple-500 to-blue-500 lg:bg-gradient-to-r my-3 sm:my-6 bg-white text-gray-800 lg:text-gray-50 rounded-xl sm:rounded-3xl shadow-xl p-4 sm:p-8 lg:p-12 w-full max-w-6xl">
          <div className="w-full max-w-2xl space-y-3 sm:space-y-6">
            <div className="text-lg font-bold">STEP 3</div>
            <h2 className="text-4xl font-extrabold mb-4">Summary</h2>

            <div className="bg-blue-50 rounded-lg p-6 shadow-sm">
              <p className="text-xl bg-white rounded-2xl p-8 shadow-lg text-gray-800">
                {summary}
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => copySummary(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Copy summary"
                >
                  {isSummaryCopied ? (
                    <CheckCircle size={24} className="text-green-500" />
                  ) : (
                    <Copy size={24} className="text-gray-500" />
                  )}
                </button>

                <button
                  onClick={() => shareTranscription(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Share summary"
                >
                  <Share2 size={24} className="text-gray-500" />
                </button>

                <button
                  onClick={() => exportToPDF(summary)}
                  className="mt-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="Export to PDF"
                >
                  <Download size={24} className="text-gray-500" />
                </button>
              </div>
            </div>
            <div className="flex w-full justify-end mt-3">
              <div className="gap-2 flex w-full">
                <button
                  onClick={startNew}
                  className="bg-blue-600 text-white px-8 py-4 w-3/5 rounded-full text-xl font-semibold hover:bg-blue-700 shadow-[0px_15px_27px_2px_rgba(37,110,255,0.28)]  transition duration-300"
                >
                  Start new
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
