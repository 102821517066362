import React from "react";

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { number: 1, name: "Upload" },
    { number: 2, name: "Transcribe" },
    { number: 3, name: "Summarize" },
  ];

  return (
    <div className="flex items-center justify-between max-w-6xl w-full mb-4">
      {steps.map((step, index) => (
        <React.Fragment key={step.number}>
          <div className="flex items-center">
            <div
              className={`w-6 h-6 rounded-full flex items-center justify-center text-xs font-semibold ${
                currentStep > step.number
                  ? "bg-green-500 text-white"
                  : currentStep === step.number
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
            >
              {currentStep > step.number ? "✓" : step.number}
            </div>
            <span
              className={`ml-2 text-sm ${
                currentStep >= step.number
                  ? "text-gray-700 font-medium"
                  : "text-gray-400"
              }`}
            >
              {step.name}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div className="flex-1 h-px bg-gray-200 mx-4"></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
export default StepIndicator;
